import styled from "styled-components";
import { FC, MouseEventHandler } from "react";

const StyledLink = styled.a`
  margin-left: 11px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3987cc;
`;

const AppStoreBadge = styled.img`
  border-radius: 13px;
  display: inline-block;
  width: ${(props) => `${props.width}px`};
`;

const AppLink: FC<{
  href: string;
  badgeWidth?: number;
  badgeUrl: string;
  isMobile: boolean;
  label: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}> = ({ badgeWidth = 120, badgeUrl, href, isMobile, label, onClick }) => (
  <StyledLink
    href={href}
    target="_blank"
    rel="noreferrer"
    title={label}
    onClick={onClick}
    style={{ display: isMobile ? "inline" : "block" }}
  >
    {isMobile ? <AppStoreBadge src={badgeUrl} width={badgeWidth} /> : label}
  </StyledLink>
);
export default AppLink;
