import { FC } from "react";
import styled from "styled-components";

interface SeparatorProps {
  fullCardWidth?: boolean;
  display?: string;
  marginLeft?: string;
  marginRight?: string;
  height?: string;
}

const StyledSeparator = styled.hr<SeparatorProps>`
    background: #d8d8dc;
    border: none;
    height: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    display: ${(props) => props.display};
`;

const Separator: FC<SeparatorProps> = ({ fullCardWidth, ...props }) => (
  <StyledSeparator
    data-testid="styledSeparator"
    marginLeft={fullCardWidth ? "-16px" : "0"}
    marginRight={fullCardWidth ? "-16px" : "0"}
    {...props}
  />
);

export default Separator;
