import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect, useState } from "react";
import OldMfa from "./components/OldMfa";
import { DataContext, DataContextShape } from "./utils";
import initialiseI18n from "./i18n";
import BlockedP2Mfa from "./components/BlockedP2Mfa";

const queryClient = new QueryClient();

export interface SPAProps extends Omit<DataContextShape, "justActivated" | "setJustActivated">{
  mfaP2: boolean;
}
export default function Root({
  accountId,
  clientId,
  countryCode,
  env,
  language,
  mfaP2,
}: SPAProps) {
  useEffect(() => {
    initialiseI18n(language).then(() => {
      setTranslationsLoaded(true);
    });
  }, [language]);
  const [justActivated, setJustActivated] = useState(false);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  if (!translationsLoaded) {
    return null;
  }

  return (
    <DataContext.Provider
      value={{
        accountId,
        clientId,
        countryCode,
        env,
        language,
        justActivated,
        setJustActivated,
      }}
    >
      <QueryClientProvider client={queryClient}>
        {
          mfaP2 ? <BlockedP2Mfa/> : <OldMfa />
        }
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </DataContext.Provider>
  );
}
