import { get } from "js-cookie";
import { CountryCode } from "./types";

export interface AuthHeaders extends Record<string, string> {
  CST: string;
  "X-SECURITY-TOKEN": string;
}

export const getAuthHeaders = (): AuthHeaders => ({
  CST: get("CST"),
  "X-SECURITY-TOKEN": get("X-SECURITY-TOKEN"),
});

const HELP_URLS: Record<string, string> = {
  AE_en_GB: "https://www.ig.com/ae/help-and-support/accounts-and-statements/statements/how-do-i-set-up-two-factor-authentication",
  AE_ar_AE: "https://www.ig.com/ar-ae/help-and-support/accounts-and-statements/statements/how-do-i-set-up-two-factor-authentication",
  AT: "https://www.ig.com/at/hilfe-und-support/konten-und-kundengelder/meine-konten/zwei-faktor-authentifizierung",
  AU: "https://www.ig.com/au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  AU_zh_CN:
    "https://www.ig.com/zh-au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  AU_zh_TW:
    "https://www.ig.com/cn-au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  BM_en_GB: "https://www.ig.com/en/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  BM_es_ES: "https://www.ig.com/es/portal-de-ayuda/cuentas-y-extractos/mis-cuentas/la-doble-autenticacion",
  BM_zh_CN: "https://www.ig.com/zh-au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  BM_zh_TW: "https://www.ig.com/cn-au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  CH: "https://www.ig.com/en-ch/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  CH_de_DE:
    "https://www.ig.com/ch/hilfe-und-support/konten-und-kundengelder/meine-konten/wie-kann-ich-die-zwei-faktor-authentifizierung-einstellen",
  CH_fr_FR:
    "https://www.ig.com/fr-ch/portail-d-aide/comptes-et-releves/mon-compte/authentification-a-double-facteur",
  CH_it_IT:
    "https://www.ig.com/it-ch/assistenza-e-supporto/conti-e-fondi/i-miei-conti/come-imposto-la-doppia-autenticazione",
  CN: "https://www.ig.com/zh-au/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  DE: "https://www.ig.com/de/hilfe-und-support/konten-und-kundengelder/meine-konten/wie-kann-ich-die-zwei-faktor-authentifizierung-einstellen",
  ES: "https://www.ig.com/es/portal-de-ayuda/cuentas-y-extractos/mis-cuentas/la-doble-autenticacion",
  FR: "https://www.ig.com/fr/portail-d-aide/comptes-et-releves/mon-compte/authentification-a-double-facteur",
  GB: "https://www.ig.com/uk/help-and-support/accounts-and-statements/account-details/two-factor-authentication",
  IE: "https://www.ig.com/uk/help-and-support/accounts-and-statements/account-details/two-factor-authentication",
  IT: "https://www.ig.com/it/assistenza-e-supporto/conti-e-fondi/i-miei-conti/come-imposto-la-doppia-autenticazione",
  JP: "https://www.ig.com/jp/help-and-support/accounts-and-statements/account-details/two-factor-authentication",
  NL: "https://www.ig.com/nl/help-en-support/rekeningen-en-overzichten/mijn-rekeningen/hoe-kan-ik-two-factor-authenticatie-instellen1",
  NO: "https://www.ig.com/no/hjelp-og-support/kontoer-og-utskrifter/mine-kontoer/hvordan-oppretter-jeg-totrinnsverifisering",
  SE: "https://www.ig.com/se/hjalp-och-support/konton-och-utdrag/mina-konton/hur-staller-jag-in-tvafaktorsautentisering",
  SG: "https://www.ig.com/sg/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  US: "https://www.ig.com/us/help-and-support/accounts-and-statements/my-accounts/two-factor-authentication",
  ZA: "https://www.ig.com/za/help-and-support/accounts-and-statements/statements/how-do-i-set-up-two-factor-authentication",
};

export const getHelpUrl = (
  countryCode: CountryCode,
  language: string
): string =>
  HELP_URLS[`${countryCode}_${language}`] ??
  HELP_URLS[countryCode] ??
  HELP_URLS.GB;
