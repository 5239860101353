import { Card } from "@ig-phoenix/card";
import { Paragraph } from "@ig-phoenix/paragraph";
import { Header } from "@ig-phoenix/header";
import { Button } from "@ig-phoenix/button";
import { Close } from "@ig-phoenix/icon";
import { Input } from "@ig-phoenix/input";
import { Modal } from "@ig-phoenix/modal";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import Separator from "./Separator";
import AppLink from "./AppLink";
import { useActivateFactorCodeOld, useGetQrCodeOld } from "../utils";
import "../styles/light.css";

const StyledHeader = styled(Header)`
  font-weight: 400;
`;
const CloseButton = styled.button`
  all: unset;
  margin: -24px -24px 0 auto;
  padding: 8px;
  cursor: pointer;
`;
const AppLinksContainer = styled.div<{ display: string }>`
  align-items: center;
  display: ${(props) => props.display};
`;

const TFActivationPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const [passCode, setPassCode] = useState(null);
  const [appQrCode, setAppQrCode] = useState(null);
  const factorData = useGetQrCodeOld();
  const activate = useActivateFactorCodeOld();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassCode(event.target.value);
  };

  return (
    <Card>
      <Header
        variant="h4"
        size="medium"
        textAlign="left"
        marginBottom="16px"
        marginTop="8px"
      >
        {t("set-up-2FA")}
      </Header>
      <Separator fullCardWidth />
      <StyledHeader
        variant="h6"
        size="small"
        textAlign="left"
        marginTop="24px"
        marginBottom="48px"
      >
        {t("2FA-explainer")}
      </StyledHeader>
      <StyledHeader
        variant="h6"
        size="small"
        textAlign="left"
        marginBottom="16px"
      >
        1. {t("authenticate-step-1")}
      </StyledHeader>
      <section>
        <div className="TFA_Download img-container" />
        <AppLinksContainer
          className="text-container"
          display={isMobile ? "inline-flex" : "inline-block"}
        >
          <AppLink
            badgeUrl="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=120x42&amp;releaseDate=1565827200"
            href="https://apps.apple.com/us/app/ig-authentication/id885821846"
            isMobile={isMobile}
            label={t("get-ios-app")}
            onClick={(e) => {
              if (isMobile) {
                return;
              }
              e.preventDefault();
              setAppQrCode((e.target as HTMLAnchorElement).href);
            }}
          />
          <AppLink
            badgeUrl="https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png"
            href="https://play.google.com/store/apps/details?id=com.igauthentication"
            isMobile={isMobile}
            label={t("get-android-app")}
            onClick={(e) => {
              if (isMobile) {
                return;
              }
              e.preventDefault();
              setAppQrCode((e.target as HTMLAnchorElement).href);
            }}
            badgeWidth={150}
          />
        </AppLinksContainer>
      </section>
      <Separator />
      <StyledHeader
        variant="h6"
        size="small"
        textAlign="left"
        marginBottom="16px"
        marginTop="24px"
      >
        2. {t("authenticate-step-2")}
      </StyledHeader>
      <section>
        <div className="TFA_Qr img-container" />
        <div className="text-container">
          <Button variant="primary" onClick={() => factorData.mutate()}>
            {t("generate-QR-code")}
          </Button>
          {factorData.isSuccess && (
            <>
              <img
                src={`data:image/gif;base64,${factorData.data.qrCode}`}
                alt="QR code"
                data-e2e="qrCode"
                height="150"
                width="150"
              />
              <Paragraph size="small" marginBottom="4px">
                {t("secret")}: {factorData.data.serialNumber}
              </Paragraph>
              <Paragraph size="small">
                {t("activation-code")}: {factorData.data.activationCode}
              </Paragraph>
            </>
          )}
        </div>
      </section>
      <Separator />
      <StyledHeader
        variant="h6"
        size="small"
        textAlign="left"
        marginBottom="16px"
        marginTop="24px"
      >
        3. {t("authenticate-step-3")}
      </StyledHeader>
      <section>
        <div className="TFA_code img-container" />
        <div className="text-container">
          <Paragraph size="medium" marginBottom="8px" marginTop="48px">
            <strong>
            {t("add-auth-code")}
            </strong>
          </Paragraph>
          <div className="input_container">
            <Input
              variant="medium"
              type="number"
              title="activation_code"
              id="activation_code"
              onChange={onChange}
              placeholder="123456"
              invalid={activate.isError}
            />
            {
              // FIXME There is no officially designed failure state in figma
              activate.isError && (
                <Paragraph variant="critical">{t("invalid-code")}</Paragraph>
              )
            }
          </div>
          <Button
            variant="primary"
            onClick={() => activate.mutate({ passCode })}
          >
            {t("activate")}
          </Button>
        </div>
      </section>
      {appQrCode && (
        <Modal>
          <CloseButton onClick={() => setAppQrCode(undefined)}>
            <Close />
          </CloseButton>
          <Header
            variant="h4"
            size="medium"
            textAlign="center"
            marginBottom="16px"
          >
            {t("download-with-qr")}
          </Header>
          <Paragraph textAlign="center">{t("scan-qr")}</Paragraph>
          <QRCode
            size={128}
            value={appQrCode}
            style={{ margin: "32px auto 16px" }}
          />
        </Modal>
      )}
    </Card>
  );
};

export default TFActivationPage;
