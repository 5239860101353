import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import translationDe from "./locales/de_DE.json";
import translationEs from "./locales/es_ES.json";
import translationFr from "./locales/fr_FR.json";
import translationIt from "./locales/it_IT.json";
import translationJp from "./locales/ja_JP.json";
import translationNl from "./locales/nl_NL.json";
import translationNo from "./locales/no_NO.json";
import translationPt from "./locales/pt_PT.json";
import translationSv from "./locales/sv_SE.json";
import translationZhCn from "./locales/zh_CN.json";
import translationZhTw from "./locales/zh_TW.json";

function initialiseI18n(lng = "en_GB") {
  return i18n.use(initReactI18next).init({
    resources: {
      en_GB: { translation: en },
      de_DE: { translation: translationDe },
      es_ES: { translation: translationEs },
      fr_FR: { translation: translationFr },
      it_IT: { translation: translationIt },
      ja_JP: { translation: translationJp },
      nl_NL: { translation: translationNl },
      no_NO: { translation: translationNo },
      pt_PT: { translation: translationPt },
      sv_SE: { translation: translationSv },
      zh_CN: { translation: translationZhCn },
      zh_TW: { translation: translationZhTw },
    },
    lng,
    fallbackLng: "en_GB",
    interpolation: {
      escapeValue: false,
    },
  });
}

export default initialiseI18n;
