import { Card } from "@ig-phoenix/card";
import { Paragraph } from "@ig-phoenix/paragraph";
import { Header } from "@ig-phoenix/header";
import { Notification } from "@ig-phoenix/notification";
import styled from "styled-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Separator from "./Separator";
import "../styles/light.css";
import { DataContext } from "../utils";

const CustomNotification = styled(Notification)`
  margin-bottom: 24px;
`;

const StyledHeader = styled(Header)`
  font-weight: 400;
`;

const TFALoginPage = () => {
  const { justActivated } = useContext(DataContext);
  const { t } = useTranslation();
  return (
    <>
      {justActivated && (
        <CustomNotification
          variant="affirmative"
          onCloseClick={() => console.log("close Banner")}
        >
          <CustomNotification.Body>
            {t("2FA-authenticated")}
          </CustomNotification.Body>
        </CustomNotification>
      )}
      <Card>
        <Header
          variant="h4"
          size="medium"
          textAlign="left"
          marginBottom="16px"
          marginTop="8px"

        >
          {t("logging-in-with-2FA")}
        </Header>
        <Separator fullCardWidth />
        <StyledHeader
          variant="h6"
          size="small"
          textAlign="left"
          marginTop="24px"
          marginBottom="24px"
        >
          {t("account-is-protected")}
        </StyledHeader>
        <StyledHeader
          variant="h6"
          size="small"
          textAlign="left"
          marginBottom="16px"
        >
          1. {t("login-step-1")}
        </StyledHeader>
        <div className="TFA_login img-container" />
        <StyledHeader
          variant="h6"
          size="small"
          textAlign="left"
          marginBottom="16px"
          marginTop="24px"
        >
          2. {t("login-step-2")}
        </StyledHeader>
        <div className="TFA_authCode img-container" />
        <Separator />
        <Header
          variant="h6"
          size="small"
          textAlign="left"
          marginBottom="16px"
          marginTop="24px"
        >
          {t("deactivate-2FA")}
        </Header>
        <Paragraph size="medium" marginBottom="8px"  marginTop="0px">
          {t("deactivate-2FA-details")}
        </Paragraph>
      </Card>
    </>
  );
};

export default TFALoginPage;
