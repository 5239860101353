import { StyleProvider } from "@ig-phoenix/style-provider";
import { Card } from "@ig-phoenix/card";
import { Header } from "@ig-phoenix/header";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@ig-phoenix/paragraph";
import { Link } from "@ig-phoenix/link";
import { useContext } from "react";
import Separator from "./Separator";
import { DataContext, getHelpUrl } from "../utils";

const BlockedP2Mfa = () => {
  const { t } = useTranslation();
  const { countryCode, language } = useContext(DataContext);

  return (
    <StyleProvider themeSet="IGWebRebrand" namespace="web-shell">
      <Card>
        <Header
          variant="h4"
          size="medium"
          textAlign="left"
          marginBottom="16px"
          marginTop="8px"
        >
          {t("set-up-2FA-explicit")}
        </Header>
        <Separator fullCardWidth />
        <Paragraph size="medium" marginBottom="16px" marginTop="24px">
          {t("2FA-description-1")}
        </Paragraph>
        <Paragraph size="medium" marginBottom="16px">
          {t("2FA-description-2")}
        </Paragraph>
        <Link href={getHelpUrl(countryCode, language)} target="_blank">
          {t("learn-more")}
        </Link>
      </Card>
    </StyleProvider>
  );
};

export default BlockedP2Mfa;
