import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { DataContext, ENV } from "./DataContext";
import { getAuthHeaders } from "./utils";


const getDomainUrl = (env: ENV): string => {
  switch (env) {
    case "LIVE":
      return "https://api.ig.com";
    case "DEMO":
      return "https://demo-api.ig.com";
    case "UAT":
      return "https://web-api.ig.com";
    case "TEST":
    default:
      return "https://net-api.ig.com";
  }
}

interface ClientStatus {
  clientStatus: "HARDWARE_ACTIVE" | "SOFTWARE_ACTIVE" | "HARDWARE_INACTIVE" | "SOFTWARE_INACTIVE" | "NOT_ACTIVE";
}

export const useGetStatusOld = () => {
  const { accountId, env } = useContext(DataContext);
  return useQuery<ClientStatus>(["getFactors", accountId], () => axios.get<ClientStatus>(
      `${ getDomainUrl(env) }/deal/twofactor/clientenabled`,
      { method: "GET", headers: { ...getAuthHeaders(), "IG-ACCOUNT-ID": accountId } }
    ).then((response) => response.data)
  );
}

interface QRRequest {
  serialNumber: string;
  activationCode: string;
  qrCode: string;
}
export const useGetQrCodeOld = () => {
  const { accountId, env } = useContext(DataContext);
  return useMutation(["getQrCode", accountId], () => axios.get<QRRequest>(
      `${getDomainUrl(env)}/deal/twofactor/qrcode`,
      {
        headers: { ...getAuthHeaders(), "Content-Type": "application/json", "IG-ACCOUNT-ID": accountId },
      }
    ).then((response) => response.data)
  );
}

interface ActivationProps {
  passCode: string
}
export const useActivateFactorCodeOld = () => {
  const { accountId, env, setJustActivated } = useContext(DataContext);
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, ActivationProps>(["postActivateFactors", accountId],
    ({ passCode}) => axios.post(
      `${getDomainUrl(env)}/deal/twofactor/otp`,
      {otp: passCode},
      {
        headers: { ...getAuthHeaders(), "Content-Type": "application/json", "IG-ACCOUNT-ID": accountId  },
      }
    ).then((response) => response.data),
    { onSuccess: () => {
        setJustActivated(true);
        queryClient.invalidateQueries({ queryKey: ["getFactors"] })
      }}
  );
}
