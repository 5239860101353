import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root, { SPAProps } from "./root.component";

const lifecycles = singleSpaReact<SPAProps>({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  renderType: "render",
});

export const { bootstrap, mount, unmount } = lifecycles;
