import { createContext } from "react";
import { CountryCode } from "./types";

export type ENV = "TEST" | "UAT" | "DEMO" | "LIVE";

export interface DataContextShape {
  accountId: string;
  clientId: string;
  countryCode: CountryCode;
  env: ENV;
  language: string;

  justActivated: boolean;
  setJustActivated: (boolean) => void;
}

export const DataContext = createContext<DataContextShape>({
  accountId: null,
  clientId: null,
  countryCode: "GB",
  env: "TEST",
  language: "en_GB",
  justActivated: false,
  setJustActivated: null,
});
