import { StyleProvider } from "@ig-phoenix/style-provider";
import TFActivationPage from "./TFActivationPage";
import TFALoginPage from "./TFALoginPage";
import "../styles/light.css";
import { useGetStatusOld } from "../utils";

const OldMfa = () => {
  const { isLoading, isError, data } = useGetStatusOld();
  if (isLoading || isError) {
    return <>Loading</>;
  }
  return (
    <StyleProvider themeSet="IGWebClassic">
      {data.clientStatus === "HARDWARE_ACTIVE" ||
      data.clientStatus === "SOFTWARE_ACTIVE" ? (
        <TFALoginPage />
      ) : (
        <TFActivationPage />
      )}
    </StyleProvider>
  );
};

export default OldMfa;
